<template>
  <a-modal
    title="邀请链接"
    :visible="$attrs"
    :maskClosable="false"
    destroyOnClose
    width="700px"
    @cancel="handleCancel"
    :footer="null">
    <div>
      <a-textarea :value="urlVal" placeholder="" :rows="2" readonly :autosize="{ minRows: 2, maxRows: 2 }"/>
    </div>
    <div class="link-footer">
      <div>
        {{`链接有效期为:14 天,失效时间:` + dayjs(new Date()).add(14, 'day').format('YYYY-MM-DD')}}
      </div>
      <a-button  type="primary" @click="copyInviteLink">{{'复制邀请链接'}}</a-button>
    </div>
  </a-modal>
</template>
<script>
import { computed, defineComponent, onMounted, reactive, toRefs } from 'vue'
import dayjs from 'dayjs'
import { copyLink } from '@/utils/utils'
import { message } from 'ant-design-vue'
export default defineComponent({
  name: 'InviteLinkModal',
  components: {},
  props: {
    value: {
      type: String,
      default: ''
    },
    inviter: {
      type: String,
      default: ''
    },
    projectName: {
      type: String,
      default: ''
    }
  },
  setup (props, context) {
    const state = reactive({

    })
    onMounted(async () => {

    })

    const handleCancel = () => {
      context.emit('update:visible', false)
    }

    const copyInviteLink = () => {
      copyLink(urlVal.value, () => {
        message.info('复制成功')
      })
    }

    const urlVal = computed(() => {
      return `${props.value} ${props.inviter} 邀请您加入'${props.projectName}'项目，把链接复制到浏览器后即可加入！`
    })

    return {
      ...toRefs(state),
      handleCancel,
      dayjs,
      copyInviteLink,
      urlVal
    }
  }
})
</script>
<style lang='less' scoped>
.link-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
</style>
