<template>
  <a-modal
    :visible="$attrs"
    :maskClosable="false"
    destroyOnClose
    width="700px"
    @cancel="handleCancel"
    :footer="null">
    <div class="result-modal">
      <div class="result-icon">
        <CheckCircleFilled />
      </div>
      <div class="result-text">
        <!-- <span>你已成功发出邀请 <span class="success-number">{{successNum}}</span> 个邮箱，失败 <span class="fault-number">{{failNum}}</span> 个邮箱, </span>
        <a :href="dataUrl">下载失败数据</a>
        <span>请按提示原因修改后重新邀请</span> -->
        你的邀请已发送到邮箱
      </div>
    </div>
    <div class="result-button">
      <a-button  type="primary" @click="handleCancel">完成</a-button>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { CheckCircleFilled } from '@ant-design/icons-vue'
export default defineComponent({
  name: 'InviteResultModal',
  components: {
    CheckCircleFilled
  },
  props: {
    successNum: {
      type: Number,
      default: 0
    },
    failNum: {
      type: Number,
      default: 0
    },
    dataUrl: {
      type: String,
      default: ''
    }
  },
  setup (props, context) {
    const state = reactive({

    })
    onMounted(async () => {

    })
    const handleCancel = () => {
      context.emit('update:visible', false)
    }
    return {
      ...toRefs(state),
      handleCancel
    }
  }
})
</script>
<style lang='less' scoped>
.result-modal {
    height: 332px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .result-icon {
        font-size: 60px;
        margin-bottom: 30px;
        color: green
    }
    .result-text {
        span.fault-number {
            color: red;
        }
    }
}
.result-button {
    display: flex;
    justify-content: flex-end;
}
</style>
