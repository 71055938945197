<template>
  <a-modal
    :visible="$attrs"
    :title="$t('admin.invite.invitePerson')"
    :maskClosable="false"
    destroyOnClose
    @ok="submitForm"
    :okText="$t(`admin.invite.${getOkText()}`)"
    width="40%"
    @cancel="handleCancel">
    <div style="max-height: 60vh;">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="email" :tab="$t('admin.invite.inviteByEmail')">
          <InviteForm
            ref="formRef"
            :enterpriseRoleOptions="enterpriseRoleList"
            :projectOptions="projectList"
            @projectChange="getProjectRoleList"
            :projectRoleOptions="projectRoleList"
            :isProject="isProject" />
        </a-tab-pane>
        <a-tab-pane key="link" :tab="$t('admin.invite.inviteByLink')" force-render>
          <InviteForm
            ref="formRef1"
            isLink
            :enterpriseRoleOptions="enterpriseRoleList"
            :projectOptions="projectList"
            @projectChange="getProjectRoleList"
            :projectRoleOptions="projectRoleList"
            :isProject="isProject" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import InviteForm from '@/components/admin/invite/InviteForm'
import api from '@/services/api'
import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'InviteModal',
  components: {
    InviteForm
  },
  props: {
    isProject: {
      type: Boolean,
      default: false
    }
  },
  setup (props, context) {
    const route = useRoute()
    const formRef = ref(null)
    const formRef1 = ref(null)

    const state = reactive({
      activeKey: 'email',
      enterpriseRoleList: [],
      projectList: [],
      projectRoleList: []
    })

    const getEnterpriseRoleList = async () => {
      const orgId = route.params?.orgId
      if (orgId) {
        try {
          const res = await api.enterprise.getRoleList({
            orgId
          })
          if (res && res.code === 200) {
            state.enterpriseRoleList = res.data.roles
          }
        } catch (error) {

        }
      }
    }

    const getProjectList = async () => {
      const orgId = route.params?.orgId
      if (orgId) {
        try {
          const res = await api.project.getProjectList({
            orgId,
            m: 'loc'
          })
          if (res && res.code === 200) {
            state.projectList = res.data.projects
            if (state.projectList && state.projectList.length) {
              getProjectRoleList(state.projectList[0].projectId)
            }
          }
        } catch (error) {

        }
      }
    }

    const getProjectString = (projectId) => {
      const current = state.projectList.find(i => i.projectId === projectId)
      if (current) {
        context.emit('currentProject', current.projectName)
      }
    }

    const getProjectRoleList = async (projectId) => {
      getProjectString(projectId)
      const orgId = route.params?.orgId
      if (orgId) {
        try {
          const res = await api.project.getProjectRoleList({
            orgId,
            projectId
          })
          if (res && res.code === 200) {
            state.projectRoleList = res.data
          }
        } catch (error) {

        }
      }
    }

    onMounted(async () => {
      if (!props.isProject) {
        getEnterpriseRoleList()
        getProjectList()
      } else {
        const projectId = route.params?.projectId
        if (projectId) {
          getProjectRoleList(projectId)
        }
      }
    })
    const getOkText = () => {
      let str = ''
      switch (state.activeKey) {
        case 'email':
          str = 'invite'
          break

        default:
          str = 'invite'
          break
      }
      return str
    }
    const submitForm = async () => {
      let mainRef
      if (state.activeKey === 'email') {
        mainRef = formRef
      } else {
        mainRef = formRef1
      }
      const form = mainRef.value?.getForm()
      try {
        await form.validate()
        if (state.activeKey === 'email') {
          context.emit('emailSubmit', mainRef.value?.getFormData())
        } else {
          context.emit('linkSubmit', mainRef.value?.getFormData())
        }
      } catch {

      }
    }
    const handleCancel = () => {
      context.emit('update:visible', false)
    }
    return {
      ...toRefs(state),
      submitForm,
      handleCancel,
      getOkText,
      formRef,
      formRef1,
      getProjectRoleList
    }
  }
})
</script>
<style lang='less' scoped>

</style>
