<template>
  <a-form
    :model="formState"
    autocomplete="off"
    layout="vertical"
    ref="formRef">
    <a-form-item
      v-if="!isProject"
      :label="$t('admin.invite.enterpriseRole')"
      name="enterpriseRole"
      :rules="[{ required: true, message: $t('admin.invite.enterRole'), trigger: 'change' }]"
    >
      <a-select
        v-model:value="formState.enterpriseRole"
        mode="multiple">
        <a-select-option
          :value="item.orgRoleId"
          v-for="(item) in enterpriseRoleOptions"
          :key="item.orgRoleId">{{item.orgRoleName}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item
      v-if="!isProject"
      :label="$t('admin.invite.project')"
      name="project"
      :rules="[{ required: true, message: $t('admin.invite.enterProject'), trigger: 'change' }]">
      <a-select
        v-model:value="formState.project"
        @change="$emit('projectChange', formState.project);formState.projectRole = []"
        :firstActiveValue="projectOptions[0]">
        <a-select-option
          :value="item.projectId"
          v-for="(item) in projectOptions"
          :key="item.projectId">{{item.projectName}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item
      :label="$t('admin.invite.projectRole')"
      name="projectRole"
      :rules="[{ required: true, message: $t('admin.invite.enterRole'), trigger: 'change' }]">
      <a-select
        v-model:value="formState.projectRole"
        mode="multiple">
        <a-select-option
          :value="item.projectRoleId"
          v-for="(item) in projectRoleOptions"
          :key="item.projectRoleId">{{item.projectRoleName}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item
      v-if="!isLink"
      :label="$t('admin.invite.inviteEmail')"
      name="email">
      <a-alert :message="$t('admin.invite.inviteEmailTips')" type="info" show-icon />
      <div style="padding: 5px"></div>
      <a-textarea v-model:value="formState.email" placeholder="" :rows="4" />
    </a-form-item>
  </a-form>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs, ref, watchEffect } from 'vue'
export default defineComponent({
  name: 'InviteForm',
  components: {},
  props: {
    isProject: {
      type: Boolean,
      default: false
    },
    isLink: {
      type: Boolean,
      default: false
    },
    enterpriseRoleOptions: {
      type: Array,
      default: () => []
    },
    projectOptions: {
      type: Array,
      default: () => []
    },
    projectRoleOptions: {
      type: Array,
      default: () => []
    }
  },
  setup (props, context) {
    const formRef = ref(null)

    const state = reactive({
      formState: {}
    })
    onMounted(async () => {

    })

    const getForm = () => {
      return formRef.value
    }

    const getFormData = () => {
      return state.formState
    }

    watchEffect(() => {
      if (props.projectOptions) {
        state.formState.project = props.projectOptions[0]?.projectId || null
      }
    })

    return {
      ...toRefs(state),
      formRef,
      getForm,
      getFormData
    }
  }
})
</script>
<style lang='less' scoped>

</style>
